<template>
  <Layout>
    <PageHeader title="Edit Patient Story" :items="items"> </PageHeader>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-form enctype="multipart/form-data" ref="forum">
              <div class="row">
                <b-form-group class="col-6">
                  <label>URL <span style="color: red">*</span></label>
                  <b-form-input
                    placeholder="Enter URL"
                    v-model="updateForm.url"
                    :class="{
                      'is-invalid': submitted && $v.updateForm.url.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.updateForm.url.required"
                    class="invalid-feedback"
                  >
                    URL is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-6">
                  <label>Meta Title <span style="color: red">*</span></label>
                  <b-form-input
                    placeholder="Enter Meta Title"
                    v-model="updateForm.meta_title"
                    :class="{
                      'is-invalid':
                        submitted && $v.updateForm.meta_title.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.updateForm.meta_title.required"
                    class="invalid-feedback"
                  >
                    Meta Title is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-6">
                  <label
                    >Meta Description <span style="color: red">*</span></label
                  >
                  <b-form-input
                    placeholder="Enter Meta Description"
                    v-model="updateForm.meta_description"
                    :class="{
                      'is-invalid':
                        submitted && $v.updateForm.meta_description.$invalid,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.updateForm.meta_description.required"
                    class="invalid-feedback"
                  >
                    Meta Description is required.
                  </div>
                </b-form-group>
                <b-form-group class="col-6">
                  <label>Meta Image <span style="color: red">*</span></label>
                  <b-form-file
                    accept=".jpg,.png,.svg"
                    @change="readFile($event, 'meta_image')"
                    ref="meta_image"
                    placeholder="Choose a file or drop it here"
                  >
                  </b-form-file>
                  <template v-if="edit.meta_image_url">
                    <img
                      :src="edit.meta_image_url"
                      width="128px"
                      height="128px"
                      style="object-fit: contain; margin-top: 5px"
                    />
                  </template>
                </b-form-group>
              </div>
              <b-button
                type="submit"
                variant="primary"
                class="mr-2"
                @click.prevent="submitData"
              >
                Update Data
              </b-button>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "@/mixins/request";
import clinicMixin from "@/mixins/ModuleJs/patient-stories";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Layout,
    PageHeader,
  },
  mixins: [MixinRequest, clinicMixin],
  data() {
    return {
      submitted: false,
      items: [
        {
          text: "Back",
          href: `/clinic/edit-clinic/${this.$route.params.id}`,
        },
        {
          text: "Data",
        },
      ],
    };
  },
  validations: {
    form: {
      url: { required },
      meta_title: { required },
      meta_description: { required },
    },
  },
  created() {
    this.fetchSingleHospital();
  },
};
</script>
