var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":"Edit Patient Story","items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('b-form',{ref:"forum",attrs:{"enctype":"multipart/form-data"}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("URL "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.updateForm.url.$invalid,
                  },attrs:{"placeholder":"Enter URL"},model:{value:(_vm.updateForm.url),callback:function ($$v) {_vm.$set(_vm.updateForm, "url", $$v)},expression:"updateForm.url"}}),(_vm.submitted && !_vm.$v.updateForm.url.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" URL is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Meta Title "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid':
                      _vm.submitted && _vm.$v.updateForm.meta_title.$invalid,
                  },attrs:{"placeholder":"Enter Meta Title"},model:{value:(_vm.updateForm.meta_title),callback:function ($$v) {_vm.$set(_vm.updateForm, "meta_title", $$v)},expression:"updateForm.meta_title"}}),(_vm.submitted && !_vm.$v.updateForm.meta_title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Meta Title is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Meta Description "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-input',{class:{
                    'is-invalid':
                      _vm.submitted && _vm.$v.updateForm.meta_description.$invalid,
                  },attrs:{"placeholder":"Enter Meta Description"},model:{value:(_vm.updateForm.meta_description),callback:function ($$v) {_vm.$set(_vm.updateForm, "meta_description", $$v)},expression:"updateForm.meta_description"}}),(_vm.submitted && !_vm.$v.updateForm.meta_description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Meta Description is required. ")]):_vm._e()],1),_c('b-form-group',{staticClass:"col-6"},[_c('label',[_vm._v("Meta Image "),_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")])]),_c('b-form-file',{ref:"meta_image",attrs:{"accept":".jpg,.png,.svg","placeholder":"Choose a file or drop it here"},on:{"change":function($event){return _vm.readFile($event, 'meta_image')}}}),(_vm.edit.meta_image_url)?[_c('img',{staticStyle:{"object-fit":"contain","margin-top":"5px"},attrs:{"src":_vm.edit.meta_image_url,"width":"128px","height":"128px"}})]:_vm._e()],2)],1),_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitData.apply(null, arguments)}}},[_vm._v(" Update Data ")])],1)],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }